import React, {
    useMemo,
    useState
} from "react"
import moment from 'moment'
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts"
import { TailSpin } from "react-loader-spinner"
import DateFilter from '../DateFilter'

import styles from "../style.module"
import utils from "../utils"

const Graph = ({
    data,
    onStartDateChange,
    startDate
}) => {
    const [endDate, _setEndDate] = useState(moment())

    const formatDate = (date) => moment(date).format('MMMM DD, YYYY')
    const startOf = (date) => date.startOf('day').valueOf()

    const graphDateRange = (startDate, endDate) => {
        const dateRange = {}
        const stopDate = moment(endDate)
        let currentDate = moment(startDate)
        while (currentDate <= stopDate) {
            dateRange[startOf(currentDate)] = { 'count': 0, 'date': formatDate(moment(currentDate)) }
            currentDate = currentDate.add(1, 'days')
        }
        return dateRange
    }

    const setDateRange = (dateRange) => {
        let newStartDate
        switch (dateRange) {
            case 'week':
                newStartDate = moment().subtract(7, 'days')
                break;
            case 'month':
                newStartDate = moment().subtract(1, 'months')
                break;
            case 'quarter':
                newStartDate = moment().subtract(3, 'months')
                break;
            case 'year':
                newStartDate = moment().subtract(12, 'months')
                break;
            default:
                break;
        }
        onStartDateChange(newStartDate)
    }

    const graphData = useMemo(() => {
        if (!data) {
            return false
        }

        let totalCount = 0
        let graphDataByDates = graphDateRange(startDate, endDate)
        for (const envelop of data) {
            const shareDate = startOf(moment(envelop.created_at))
            if (graphDataByDates[shareDate]) {
                const shareCount = envelop.documents.concat(envelop.agreements).length
                graphDataByDates[shareDate].count = shareCount + (graphDataByDates[shareDate].count || 0)
                totalCount = totalCount + shareCount
            }
        }

        graphDataByDates = utils.sortByReversedKey({ object: graphDataByDates })

        return { 'graphData': Object.values(graphDataByDates), 'totalCount': totalCount }
    }, [data, startDate, endDate])

    const exportUrl = useMemo(() => {
        const exportDate = startDate.clone()
        exportDate.subtract(1, 'days')
        return `/dashboard/export?before=${exportDate.format()}`
    }, [startDate])

    return <div className={`content ${styles.module}`} style={{ height: '400px' }}>
        <div className="columns">
            <div className="column is-flex">
                <h2>Shares</h2>
                <DateFilter
                    onChange={(dateFilter) => setDateRange(dateFilter)} />
                <a href={exportUrl} target="_blank" style={{ display: 'block', marginLeft: '16px', fontSize: '14px', lineHeight: '24px' }}>Export</a>
            </div>
            {graphData && <div className="column has-text-right">{graphData.totalCount} Total Shares</div>}
        </div>

        <div className="columns">
            {graphData && <div className="column" style={{ marginLeft: "-20px" }}>
                <ResponsiveContainer
                    height={300}
                    width="100%">
                    <BarChart
                        data={graphData.graphData}
                        margin={{ right: 50, left: 50 }}>
                        <CartesianGrid
                            stroke="#ced0d2"
                            vertical={false} />
                        <Tooltip
                            cursor={false} />
                        <Bar
                            dataKey="count"
                            fill="#0075e0"
                            legendType={'none'}
                            name="Shares" />
                        <XAxis
                            dataKey="date"
                            tick={{ fontSize: 'smaller' }}
                            tickLine={false}
                            axisLine={false} />
                        <YAxis
                            dataKey="count"
                            allowDecimals={false}
                            tick={{ fontSize: 'smaller' }}
                            tickLine={false}
                            axisLine={false}
                            tickMargin="70" />
                    </BarChart>
                </ResponsiveContainer>
            </div>}
            {!graphData && <div className="is-flex is-align-items-center is-justify-content-center" style={{ width: '100%', marginTop: '130px' }}>
                <TailSpin color="#18242d" height={24} width={24} />
            </div>}
        </div>
    </div>
}

export default Graph